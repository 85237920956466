import React, {useState} from "react"
import "../../styles/tab-events.scss"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Banner({ data, slug, location }) {
    var tabs = Array(data?.tabs?.length).fill(false)
    tabs[0] = true
    const[tabsMobile, setTabsMobile] = useState(tabs)

    const widthOfTabs = (1 / data?.tabs?.length) * 100;

    function openTab(index) {
        tabs = tabsMobile
        var openedTab = !tabs[index]
        tabs.fill(false)
        tabs[index] = openedTab
        return setTabsMobile([...tabs])
    }


    return (
        <div className="tab-events-wrapper">
            <div data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="1s"
                 data-sal-easing="ease-in" className="tabs-desktop">
                <Tabs>
                    <TabList>
                        {data?.tabs?.map(tab => {
                            return (
                                <Tab style={{width: widthOfTabs + '%'}}>{tab?.title}</Tab>
                            )
                        })}
                    </TabList>
                    {data?.tabs?.map(tab => {
                        const image = getImage(tab?.image?.localFile?.childImageSharp?.gatsbyImageData)

                        return (
                            <TabPanel>
                                <GatsbyImage image={image} alt={tab?.image?.altText} />
                                <div className="text-content-wrapper">
                                    <div className="text-content">
                                        <div className="description" dangerouslySetInnerHTML={{__html: tab?.content}}></div>
                                        <Link className="button" to={tab?.button?.url}>{tab?.button?.title}</Link>
                                    </div>
                                </div>
                            </TabPanel>
                        )
                    })}
                </Tabs>
            </div>
            <div className="tabs-mobile">
                {data?.tabs?.map((tab, index) => {
                    return (
                        <div className={"tab " + (!tabsMobile[index] ? 'disabled' : '')}>
                            <h2 onClick={() => openTab(index)}>{tab?.title}</h2>
                            <div className="content">
                                <img src={tab?.image?.localFile?.publicURL} alt={tab?.image?.altText} loading="lazy" />
                                <div className="text-content">
                                    <div className="description description__toggle" dangerouslySetInnerHTML={{__html: tab?.content}}></div>
                                    <Link className="button" to={tab?.button?.url}>{tab?.button?.title}</Link>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
